import React from 'react';
import '../i18n';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import SectionView from '../components/common/SectionView';
import { stringBreakToParagraphs } from '../../utils/tools';
import Paragraph from '../components/common/Paragraph';
import Features from '../components/pages/home/Features';
import Divider from '../components/common/Divider';
import HomeCarousel from '../components/pages/home/HomeCarousel';
import DownloadApp from '../components/pages/home/DownloadApp';
import PartnerWithUs from '../components/pages/home/PartnerWithUs';

export default function Home({ location }) {
  const { t } = useTranslation();

  return (
    <PageContainer
      backgroundColor="white"
      pageTitle={t('navMenu.home')}
      location={location}
    >
      <HomeCarousel />
      <Features />
      <Divider />
      <SectionView
        title={t('home.about.title')}
        subtitle={t('home.about.subtitle')}
      >
        {stringBreakToParagraphs(t('home.about.content1')).map(
          (item, index) => {
            return <Paragraph key={index}>{item}</Paragraph>;
          }
        )}
        {stringBreakToParagraphs(t('home.about.content2')).map(
          (item, index) => {
            return <Paragraph key={index}>{item}</Paragraph>;
          }
        )}
        {stringBreakToParagraphs(t('home.about.content3')).map(
          (item, index) => {
            return <Paragraph key={index}>{item}</Paragraph>;
          }
        )}
      </SectionView>
      <DownloadApp />
      <PartnerWithUs location={location} />
    </PageContainer>
  );
}
