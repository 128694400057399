import React from 'react';
import SectionView from '../../common/SectionView';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'antd';
import { createStyle } from '../../../../utils/tools';
import { Link } from 'gatsby';

const PartnerWithUs = ({ location }) => {
  const { t } = useTranslation();

  return (
    <SectionView
      title={t('home.partnerWithUs.title')}
      subtitle={t('home.partnerWithUs.subtitle')}
    >
      <div style={styles.buttonContainer}>
        <Link to={'partner' + location.search} style={{ color: 'white' }}>
          <Button type="primary" style={styles.button}>
            <FontAwesomeIcon icon={faStore} color="white" size="2x" />

            <span style={styles.buttonText}>
              {t('home.partnerWithUs.join')}
            </span>
          </Button>
        </Link>
      </div>
    </SectionView>
  );
};

const styles = createStyle({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    height: 100,
    minWidth: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 20,
  },
});

export default PartnerWithUs;
