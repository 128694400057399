import React from 'react';
import { Row, Col, Typography } from 'antd';
import SectionView from '../../common/SectionView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruck, faClock, faCarrot } from '@fortawesome/free-solid-svg-icons';
import { createStyle } from '../../../../utils/tools';
import { tintColorLight } from '../../../constants/colors';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const features = [
  {
    name: 'home.features.freeShipping',
    icon: faTruck,
  },
  {
    name: 'home.features.7day',
    icon: faClock,
  },
  {
    name: 'home.features.fresh',
    icon: faCarrot,
  },
];

const Features = () => {
  const { t } = useTranslation();

  const renderFeature = features.map((item) => {
    return (
      <Col span={12} md={6} key={item.name}>
        <div style={styles.featureContainer}>
          <div style={styles.featureIcon}>
            <FontAwesomeIcon color="white" icon={item.icon} size="4x" />
          </div>
          <Text style={{ fontSize: 16, marginTop: 6, textAlign: 'center' }}>
            {t(item.name)}
          </Text>
        </div>
      </Col>
    );
  });

  return (
    <SectionView title={t('home.features.title')}>
      <Row justify="center">{renderFeature}</Row>
    </SectionView>
  );
};

const styles = createStyle({
  featureIcon: {
    backgroundColor: tintColorLight,
    width: 120,
    borderRadius: '50%',
    height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  featureContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default Features;
