import React from 'react';
import { Carousel } from 'antd';
import { screenMaxWidth } from '../../../constants/colors';
import { createStyle } from '../../../../utils/tools';

const HomeCarousel = () => {
  return (
    <Carousel autoplay>
      <div>
        <img style={styles.bannerItem} src="/images/banner/banner1.jpg" />
      </div>
      <div>
        <img style={styles.bannerItem} src="/images/banner/banner2.jpg" />
      </div>
      <div>
        <img style={styles.bannerItem} src="/images/banner/banner3.jpg" />
      </div>
      <div>
        <img style={styles.bannerItem} src="/images/banner/banner4.jpg" />
      </div>
    </Carousel>
  );
};

const styles = createStyle({
  bannerItem: {
    width: '100vw',
    maxWidth: screenMaxWidth,
  },
});

export default HomeCarousel;
